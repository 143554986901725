import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyCkOjY6bxnsXbEzqKEqyjMK2eyoOBfjTNI",
  authDomain: "radhika-project-65559.firebaseapp.com",
  projectId: "radhika-project-65559",
  storageBucket: "radhika-project-65559.appspot.com",
  messagingSenderId: "91386095885",
  appId: "1:91386095885:web:566e9113de3dd2b70e2844",
  measurementId: "G-CCPW7NRQKD"
});

const fb = firebase;


export default fb;